import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';
import Options from './Options';
import TilHamingju from './TilHamingju';

const Root = () => (
    <Router>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/options" element={<Options />} />
            <Route path="/til-hamingju" element={<TilHamingju />}/>
        </Routes>
    </Router>
);

export default Root;
