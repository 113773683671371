import React from 'react'

const TilHamingju = () => {
  return (
    <div>
      <h1>Til hamingju með appið</h1>
    </div>
  )
}

export default TilHamingju
