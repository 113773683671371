import { useEffect } from 'react';
import { isAndroid, isIOS } from "react-device-detect";
import { useNavigate } from 'react-router-dom';

const App = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (isIOS) {
            window.location.href = "https://apps.apple.com/us/app/b%C3%B3nus/id6447761993";
            navigate('/til-hamingju');
        } else if (isAndroid) {
            window.location.href = "https://play.google.com/store/apps/details?id=com.bonus.scanpaygo";
            navigate('/til-hamingju');
        } else {
            navigate('/options');
        }
    }, [navigate]);

    return null;
}

export default App;
