const Options = () => {
  return (
    <div>
      <h2>Bónus appið</h2>
      <p>
        Bónus hefur þróað app til að auðvelda viðskiptavinum sínum að versla í
        Bónus.
      </p>
      <h4>Gripið & greitt vildarkort</h4>
      <p>
        Í appinu getur þú meðal annars nálgast Gripið & greitt vildarkort. Þú
        þarft það til að geta notað nýja sjálfsafgreiðslulausn sem Bónus er
        byrjað að bjóða viðskiptavinum sínum upp á í ákveðnum verslunum.
      </p>
      <h4>Deilanlegir innkaupalistar</h4>
      <p>
        Hægt er að búa til einn eða fleiri innkaupalista í appinu til að halda
        utanum það sem vantar á heimilið. Það er einnig hægt að deila
        innkaupalistanum með öðrum fjölskyldumeðlimum til að tryggja að ekkert
        gleymist.
      </p>
      <h4>Upplýsingar um vöruframboð</h4>
      <p>
        Í fyrsta sinn er vöruframboð Bónus aðgengilegt í gegnum snjalltæki. Hægt
        er að fá upplýsingar um verð og innihald vara og það er einnig hægt að
        leita eftir vörum eða skoða vöruflokka í appinu.
      </p>
      <h4>Rafrænar greiðslukvittanir</h4>
      <p>
        Eftir að þú ert búin að versla berast þér rafrænar greiðslukvittanir í
        appið svo það er auðvelt að skoða hvað var keypt og hvað það kostaði
        aftur í tímann.
      </p>
      <p>
      Appið er í stöðugri þróun og spennandi ný virkni
        væntanleg á næstunni.
      </p>
      <a href="https://apps.apple.com/us/app/b%C3%B3nus/id6447761993">
        <button>Download on the App Store</button>
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.bonus.scanpaygo">
        <button>Get it on Google Play</button>
      </a>
    </div>
  );
};

export default Options;
